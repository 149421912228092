// eslint-disable-next-line
import React, { Component } from 'react';
import { withFirebase } from '../../services';
/* eslint-disable  @typescript-eslint/no-unused-vars */
import tw from 'twin.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faUserCircle,
  faLock,
  faFrown,
  faThumbsDown,
  faThumbsUp,
} from '@fortawesome/free-solid-svg-icons';
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter';
import PWResetInput from '../PWResetInput';
import { AlertDialog } from '../alertDialog';
import Loading from '../../utils/loadingAnimation';

interface Props {
  firebase: any;
  match: any;
}

interface State {
  isSent: boolean;
  user: any;
  loading: boolean;
  dialogLoading: boolean;
  changePW: boolean;
  showDialog: boolean;
  success: boolean;
  error: boolean;
  successMessage: string;
  password: string;
}

class UserItem extends Component<Props, State> {
  unsubscribe: any;
  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      dialogLoading: false,
      user: null,
      changePW: false,
      showDialog: false,
      success: false,
      error: false,
      successMessage: '',
      password: '',
      ...props.location.state,
    };

    this.transitionInput = this.transitionInput.bind(this);
  }

  componentDidMount() {
    if (this.state.user) {
      return;
    }

    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .user(this.props.match.params.id)
      .onSnapshot((snapshot: any) => {
        this.setState({
          user: snapshot.data(),
          loading: false,
        });
      });
  }

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  closeModal = () => this.setState({ showDialog: false });

  onPasswordValue = (password: any) => {
    this.setState({ password });
  };

  onSubmitChangePW = async () => {
    const data = {
      password: this.state.password,
      user: this.state.user,
    };
    return await this.props.firebase
      .doHttpsCallable('sync', {
        email: data.user.email,
        password: data.password,
        uid: data.user.uid,
      })
      .then(() => {
        this.setState({
          showDialog: true,
          success: true,
          error: false,
          successMessage: `Password for ${data.user.email} has been set to ${data.password}`,
          dialogLoading: false,
          changePW: false,
        });
      })
      .catch((error: any) => {
        this.setState({
          error,
          showDialog: true,
          dialogLoading: false,
        });
      });
  };

  onSendPasswordResetEmail = async () => {
    const email = this.state.user.email;
    await this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({
          showDialog: true,
          success: true,
          error: false,
          successMessage: `A password reset link has been emailed to ${email}.`,
          dialogLoading: false,
        });
      })
      .catch((error: any) => {
        this.setState({
          error,
          showDialog: true,
          dialogLoading: false,
        });
      });
  };

  transitionInput() {
    this.state.changePW ? this.setState({ changePW: false }) : this.setState({ changePW: true });
  }

  render() {
    const {
      user,
      loading,
      changePW,
      showDialog,
      success,
      error,
      successMessage,
      dialogLoading,
    }: any = this.state;

    return (
      <div>
        {loading && <Loading />}
        <div tw="bg-white px-4 py-5 border-b border-secondary-200 sm:px-6">
          <div tw="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-no-wrap">
            <div tw="ml-4 mt-4">
              <div tw="flex items-center">
                <div tw="flex-shrink-0">
                  {user.photoURL ? (
                    <img tw="h-12 w-12 rounded-full" src={user.photoURL} alt="" />
                  ) : (
                    <FontAwesomeIcon size="3x" icon={faUserCircle} />
                  )}
                </div>
                <div tw="ml-4">
                  <h3 tw="text-lg leading-6 font-medium text-secondary-900">
                    {user.firstName ? user.firstName : 'No Name'}{' '}
                    {user.lastName ? user.lastName : 'Provided'}
                  </h3>
                </div>
              </div>
            </div>
            <div tw="ml-4 mt-4 flex-shrink-0 flex">
              <span tw="inline-flex rounded-md shadow-sm">
                <button
                  type="button"
                  tw="relative inline-flex items-center px-4 py-2 border border-secondary-900 leading-5 font-medium rounded-md text-secondary-900 bg-white hover:bg-primary-500 focus:outline-none focus:border-primary-300 active:bg-primary-500 active:text-white hover:text-white"
                  onClick={this.transitionInput}
                >
                  <FontAwesomeIcon icon={faLock} />
                  <span tw="ml-1">Change Password</span>
                </button>
              </span>
              <span tw="ml-3 inline-flex rounded-md shadow-sm">
                <button
                  type="button"
                  tw="relative inline-flex items-center px-4 py-2 border border-secondary-900 leading-5 font-medium rounded-md text-secondary-900 bg-white hover:bg-primary-500 focus:outline-none focus:border-primary-300 active:bg-primary-500 active:text-white hover:text-white"
                  onClick={this.onSendPasswordResetEmail}
                >
                  <FontAwesomeIcon icon={faEnvelope} />
                  <span tw="ml-1">Send Password Reset</span>
                </button>
              </span>
            </div>
          </div>
          {changePW ? (
            <PWResetInput
              onTypePW={this.onPasswordValue}
              onSubmitChangePW={this.onSubmitChangePW}
            />
          ) : null}
          {dialogLoading && <Loading />}
          {showDialog ? (
            <AlertDialog
              title={success ? '🎉 Password Reset Success!' : '🙁 Something Went Wrong...'}
              message={success ? successMessage : error.message}
              displayModal={showDialog}
              dismissModal={() => this.closeModal()}
              mode={success ? 'success' : 'error'}
              dismissText={'Okay'}
              dismissAction={() => {
                if (success) {
                  this.closeModal();
                } else {
                  this.closeModal();
                }
              }}
            />
          ) : null}
        </div>
        <div tw="p-6">
          <dl tw="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div tw="sm:col-span-1">
              <dt tw="text-lg leading-5 font-semibold text-gray-800">Customer Name</dt>
              <dd tw="mt-1 text-gray-900">
                {user.firstName ? user.firstName : 'Not'}{' '}
                {user.lastName ? user.lastName : 'Provided'}
              </dd>
            </div>
            <div tw="sm:col-span-1">
              <dt tw="text-lg leading-5 font-semibold text-gray-800">UID</dt>
              <dd tw="mt-1 text-gray-900">
                <a
                  href={`https://console.firebase.google.com/u/0/project/truth-to-table-beta/firestore/data~2Fusers~2F${user.uid}`}
                  target="_blank"
                  rel="noreferrer"
                  tw="underline italic"
                >
                  {user.uid}
                </a>
              </dd>
            </div>
            <div tw="sm:col-span-1">
              <dt tw="text-lg leading-5 font-semibold text-gray-800">Email Address</dt>
              <dd tw="mt-1 text-gray-900">
                {user.email ? (
                  <a
                    href={`mailto:${user.email}`}
                    target="_blank"
                    rel="noreferrer"
                    tw="underline italic"
                  >
                    {user.email}
                  </a>
                ) : (
                  'None Provided'
                )}
              </dd>
            </div>
            <div tw="sm:col-span-1">
              <dt tw="text-lg leading-5 font-semibold text-gray-800">MemberPress ID</dt>
              <dd tw="mt-1 text-gray-900">
                {user.memberpressId ? (
                  <a
                    href={`https://courses.truthtotable.com/wp-admin/admin.php?page=memberpress-members&search=${user.memberpressId}&search-field=id`}
                    target="_blank"
                    rel="noreferrer"
                    tw="underline italic"
                  >
                    {user.memberpressId}
                  </a>
                ) : (
                  'MemberPress ID'
                )}
              </dd>
            </div>
            <div tw="sm:col-span-1">
              <dt tw="text-lg leading-5 font-semibold text-gray-800">Subscriber Status</dt>
              <dd tw="mt-1 text-gray-900">
                {user.memberpressYearlySubscriber ? (
                  <a
                    href={`https://courses.truthtotable.com/wp-admin/admin.php?page=memberpress-subscriptions&search=${user.email}&search-field=email`}
                    target="_blank"
                    rel="noreferrer"
                    tw="underline italic"
                  >
                    <FontAwesomeIcon icon={faThumbsUp} />
                    <span tw="ml-2">Yearly</span>
                  </a>
                ) : user.memberpressMonthlySubscriber ? (
                  <a
                    href={`https://courses.truthtotable.com/wp-admin/admin.php?page=memberpress-subscriptions&search=${user.email}&search-field=email`}
                    target="_blank"
                    rel="noreferrer"
                    tw="underline italic"
                  >
                    <FontAwesomeIcon icon={faThumbsUp} />
                    <span tw="ml-2">Monthly</span>
                  </a>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faThumbsDown} />
                    <span tw="ml-2">No Active Subscriptions</span>
                  </>
                )}
              </dd>
            </div>
            <div tw="sm:col-span-1">
              <dt tw="text-lg leading-5 font-semibold text-gray-800">Provider ID</dt>
              <dd tw="mt-1 text-gray-900">
                {user.providerId ? (
                  user.providerId ? (
                    capitalizeFirstLetter(user.providerId)
                  ) : (
                    'Provider ID'
                  )
                ) : (
                  <FontAwesomeIcon icon={faFrown} />
                )}
              </dd>
            </div>
            <div tw="sm:col-span-1">
              <dt tw="text-lg leading-5 font-semibold text-gray-800">Date Created</dt>
              <dd tw="mt-1 text-gray-900">
                {user.metadata
                  ? user.metadata.creationTime.seconds
                    ? new Date(user.metadata.creationTime.seconds * 1000).toUTCString()
                    : new Date(user.metadata.creationTime * 1000).toUTCString()
                  : 'The Third Day'}
              </dd>
            </div>
            <div tw="sm:col-span-1">
              <dt tw="text-lg leading-5 font-semibold text-gray-800">Last Study Opened</dt>
              <dd tw="mt-1 text-gray-900">
                {user.lastStudyOpen ? (
                  user.lastStudyOpen.lastStudyId ? (
                    capitalizeFirstLetter(user.lastStudyOpen.lastStudyId)
                  ) : (
                    ''
                  )
                ) : (
                  <FontAwesomeIcon icon={faFrown} />
                )}
              </dd>
            </div>
            <div tw="sm:col-span-2">
              <dt tw="text-lg leading-5 font-semibold text-gray-800">Courses In Progress:</dt>
              <dd tw="mt-4 text-gray-900">
                {user.progress
                  ? Object.keys(user.progress).map((course: any) => (
                      <span
                        tw="inline-flex items-center m-1 px-2 py-1 rounded-md text-sm font-medium leading-5 bg-primary-500 text-white"
                        key={course}
                      >
                        {capitalizeFirstLetter(course)}
                      </span>
                    ))
                  : 'No User Progress'}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    );
  }
}

export default withFirebase(UserItem);
